<template>
    <base-form-control v-bind="formControlAttributes">
        <slot name="prepend"></slot>
        <v-select class="control" v-bind="controlAttributes" :clearable="clearable" v-model="model" :disabled="disabled"
                  :multiple="multiple" v-on="listeners" label="title" :options="options">
            <template v-slot:option="option">
                <div style="margin-left: -1.7rem; width: 100%;" class="bg-primary-trans m-0 py-3 text-white w-100 mt-2">{{ option.title }}</div>
            </template>
        </v-select>
        <slot name="append"></slot>
    </base-form-control>
</template>

<script>
import BaseInput from './BaseInput';
import VueSelectCommons from './VueSelectCommons';

export default {
    name: 'custom-form-select',
    extends: BaseInput,
    mixins: [VueSelectCommons],
    data () {
        return { model: '' };
    },
    props: {
        options: {
            type: Array,
            default () {
                return [];
            }
        }
    },
    mounted () {
        this.findModel();
    },
    methods: {}

};
</script>
