<template>
    <auth-page-template class="min-h-80" heading="Create an account" size="lg" :back-link="true">
        <template #subSection>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            </p>
        </template>
        <template #default>
            <div class="pb-lg-9 px-lg-7">
                <validated-select class="c-input-select" :options="['Apple', 'Ball', 'Cat']" placeholder="Choose Registration Type">
                </validated-select>
                <custom-form-select class="c-input-select" :options="['Apple', 'Ball', 'Cat']" placeholder="Choose Registration Type">
                </custom-form-select>
            </div>
        </template>
    </auth-page-template>
</template>

<script>
import CustomFormSelect from '@components/lego-custom/CustomFormSelect';
export default {
    name: 'ChooseRegistrationType',
    components: { CustomFormSelect }
};
</script>

<style scoped>
.min-h-80{
    min-height: 80vh !important;
}
</style>
